import axios from "@/utils/useAxios";
import { MessageBox } from "element-ui";
import { BASE_URL } from "@/utils/url.js";
import Vue from "vue";
export const EventBus = new Vue();
import router from "@/router";
import sm4 from "sm-crypto";
export default {
  /*** 获取token */
  getToken() {
    let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (userInfo == null || userInfo === "") {
      return "";
    }
    return userInfo.token;
  },

  /*** 根据key获取LocalStorage */
  getLocalStorage(key) {
    let data = JSON.parse(localStorage.getItem(key));
    if (data == null || data === "") {
      return "";
    }
    return data;
  },

  /*** 设置本地存储中的数据 */
  setLocalStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },

  /*** 清空本地缓存 */
  clear() {
    localStorage.clear();
  },
};

/** 拼接图片附件前缀 */
export function getPrefixUrl(temp) {
  const prefixUrl = `${BASE_URL}/attachment/` + temp;
  // const prefixUrl = "https://ms.readygo.org.cn/ssi-auth//attachment/" + temp;
  return prefixUrl;
}

/** 传字典类型值,字典值 查询全部手动过滤 返回字典名称  */
export function pipe(dicType, dicValue) {
  parameter(dicType).then((res) => {
    if (res.code === 200) {
      if (res.data == []) {
        return [];
      } else {
        var list = res.data;
        list.forEach((ele) => {
          if (ele.code == dicValue) {
            return ele.name;
          }
        });
      }
    }
  });
}

/** 查参数字典表 按传入type筛选*/
export const parameter = function (type) {
  return axios({
    method: "get",
    url: `/secured/parameter/list?type=${type}`,
    type,
  });
};

/** 校验是否登录 返回布尔类型 未登录进行弹窗强提示 */
export const login_status_Verify = function () {
  if (
    localStorage.getItem("userInfo") == "" ||
    localStorage.getItem("userInfo") == null
  ) {
    MessageBox.alert("您还没有登录", "提示", {
      confirmButtonText: "去登录",
      callback: (action) => {
        // router.push({
        //   path: "/",
        // });
        EventBus.$emit("callLoginDialog");
      },
    });
    return false;
  } else {
    return true;
  }
};
/** 校验是否选择了赛事 返回布尔类型 未选择进行弹窗强提示 */
export const choose_event_Verify = function () {
  if (
    localStorage.getItem("eventId") == "" ||
    localStorage.getItem("eventId") == null
  ) {
    MessageBox.alert("您没有选择要查看的赛事", "提示", {
      confirmButtonText: "去选择赛事",
      callback: (action) => {
        router.push({
          path: "/work",
        });
      },
    });
    return false;
  } else {
    return true;
  }
};
// sm4 加密
export function encrypt(params) {
  // 创建一个 SM4Util 的实例
  const sm4 = require("sm-crypto").sm4;
  const key = '1910130389abcdeffedcba9876243210';//鲁后华提供
  return sm4.encrypt(params, key);
}

// sm4 解密
export function decrypt(params) {
  // 创建一个 SM4Util 的实例
  const sm4 = require("sm-crypto").sm4;
  const key = '1910130389abcdeffedcba9876243210';//鲁后华提供
  return sm4.decrypt(params, key);
}
