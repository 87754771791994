/** 多语言-日语 */
export default {
  people: "人",
  team: "チーム",
  yuan: "元",
  day: "天",
  Donate: "捐赠",
  home_page: "ホームページ",
  people: "人",
  team: "队",
  yuan: "元",
  day: "天",
  Donate: "寄付金",
  login: "ログイン",
  sex: "性別",
  phone: "電話",
  blood: "血液型",
  birth: "生年月日",
  email: "電子メール",
  clothingSize: "服のサイズ",
  name: "名前",
  nick_name: "ニックネーム",
  telephone_number:'番号帳',
  identity:'アイデンティティ',
  raise:'資金調達額',
  ckdw:'チームの表示',
  jzzl:'助力を寄付する',
  consult:'クエリー＃クエリー＃',
  reset:'リセット',
  lxwm:'連絡先',
  age: "年齢",
  avatar: "アイコン",
  address: "アドレス",
  ordinal: "連番",
  time: "時間",
  amount: "金額",
  Team: "チーム",
  Group: "だんたい",
  team_name: "チーム名",
  slogan: "スローガン",
  team_leader: "隊長",
  send: "送信",
  volunteer: "ボランティア",
  in_progress: "進行中",
  ended: "終了しました",
  ranking_list_person: "個人ランキング",
  ranking_list_group: "団体ランキング",
  ranking_list_team: "チームランキング",
  next_step: "次へ",
  logout: "ログアウト",
  registration_team: "登録チーム",
  participating_groups: "参加チーム",
  registration_number: "応募者数",
  sign_up: "申し込み",
  participation_way: "参加方法",
  donation_amount: "寄付金額",
  notice: "イベント注意事項",
  public_welfare_project: "公益プロジェクト",
  change_event: "交換試合",
  more: "もっと表示",
  selected: "現在の選択",
  event_dynamics: "イベントの動き",
  event_news: "試合ニュース",
  event_schedule: "試合スケジュール",
  event_support: "試合サポート",
  organization_unit: "組織単位",
  want_to_donate: "寄付します",
  activity_introduction: "アクティビティ概要",
  entry_notice: "エントリーの注意事項",
  rules: "アクティビティ規則",
  route_map: "ルートマップ",
  travel_instructions: "事前に知っておくべきこと",
  donation_notice: "寄付に関する注意事項",
  FAQ: "よくある質問 ",
  donation_guide: "募金の手引き",
  donation_objective: "募金目標",
  contact_us: "お問い合わせ",
  communication_group: "ウィーチャットグループ",
  technical_support: "テクニカルサポート",
  service_call: "サービスコール",
  service_time: "サービス時間",
  message: "メッセージ通知",
  system_message: "システムメッセージ",
  team_message: "チームメッセージ",
  donation_records: "寄付記録",
  registratio_records: "申し込み記録",
  apply_invoice: "送り状を発行する",
  click_to_view: "クリックして表示",
  view_the_team: "チームの表示",
  wonderful_moment: "素晴らしい瞬間",
  join_us: "チームの表示",
  ID_type: "証明書タイプ",
  ID_number: "暗証番号",
  basic_information: "プロフィール",
  event_information: "試合試合",
  use_of_donations: "義援金の流れ",
  emergency_contact_relation: "緊急連絡先関係",
  countdown: "カウントダウン",
  team_share_code: "チームシェアコード",
  emergency_contact_number: "緊急連絡先電話番号",
  emergency_contact_name: "緊急連絡先名",
  // 提示语
  placeholder: "入力...",
  input_error: "入力エラー",
  successful_operation: "操作に成功しました",
  not_yet_open: "未公開",
  payment_method_prompt: "携帯電話のコード決済をお使いください",
  succeed: "ファイト！ファイト！私たちはきっと成功する",
  // 队伍管理
  teamManagement: "チーム管理",
  operation: "操作",
  my_medal: "私の勲章",
  total: "合計",
  pieces: "枚",
  communicationGroup: "こうりゅうぐん",
  donationCode: "寄付コード",
  shareCode: "シェアリングコード",
  teamNum: "チーム人数",
  fundraisingAmount: "募集金額",
  achieved: "達成済み",
};
