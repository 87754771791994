import Vue from "vue";
import local from "@/utils/local";
import VueI18n from "vue-i18n";
import elementEnLocale from "element-ui/lib/locale/lang/en";
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN";
import elementJaLocale from "element-ui/lib/locale/lang/ja";
import elementKoLocale from "element-ui/lib/locale/lang/ko";

import locale from "element-ui/lib/locale";
import enLocale from "./en-US";
import zhLocale from "./zh-CN";
import jaLocale from "./ja";
import koLocale from "./ko";

Vue.use(VueI18n);

// 引入本地包
const messages = {
  zh: {
    ...zhLocale,
    ...elementZhLocale,
  },
  en: {
    ...enLocale,
    ...elementEnLocale,
  },
  ja: {
    ...jaLocale,
    ...elementJaLocale,
  },
  ko: {
    ...koLocale,
    ...elementKoLocale,
  },
};

// 创建国际化实例
const i18n = new VueI18n({
  locale: local.getLocalStorage("lang") || "zh",
  messages,
});
locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
