import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import QRcode from './components/QRcode'
import VideoPlayer from 'vue-video-player'

// 引入方式一
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'
Vue.use(VideoPlayer)

Vue.use(QRcode);
// 全局引入element 组件
import ElementUI from 'element-ui'
// 全局使用
import '@/styles/index.scss'
import '@/styles/index.css'//现主题颜色为#FC3030,更改主题颜色 可替换该文件
// import 'element-ui/lib/theme-chalk/index.css'

Vue.prototype.$port = 'https://ms.readygo.org.cn/ssi-auth' // 甲方环境 小程序跳转地址(发正式包地址)

Vue.use(ElementUI)
import i18n from './lang'
new Vue({
  el: '#app',
  i18n,
  router,
  store,
  render: h => h(App)
})
